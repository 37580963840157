(function( $ ) {

  const sivomixx = {

    init: function() {
      sivomixx.noWidows($('p,small,li span, #services .features li').not('form p'));
      sivomixx.pageScroll();
      sivomixx.menuToggle();
      sivomixx.faqToggle();
      sivomixx.formOptIn();
    },
    
    // Read a page's GET URL variables and return them as an associative array.
    getParameterByName: function(name) {
      const match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    },
    
    // Event listener to scroll page to section
    pageScroll: function() {
      $('.page-scroll').on('click', function(e) {
        e.preventDefault();
        const currentLink = this.hash;
        if(currentLink.length) {
          sivomixx.scrollTo(currentLink,500);
        }
      });
        
      // If path contains a hash on load/hashchange jump straight to anchor
      $(window).on('load hashchange', function() {
        if (location.hash) {
          const currentLink = location.hash;
          sivomixx.scrollTo(currentLink,500);
        }
      });
    },

    // Nav scroll-to-section
    scrollTo: function(loc,speed) {
      const offsettery = $(loc).offset().top;
      $('html, body').stop().animate({scrollTop: offsettery}, speed);
      if(history.pushState) {
        history.pushState(null, null, loc);
      }
      else {
        location.hash = loc;
      }
    },
    
    // Remove widows from content
    noWidows: function(e) {
      e.each(function(){
        $(this).html($(this).html().replace(/\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/,'&nbsp;$1'));
      });
    },

    menuToggle: function() {
      const $menuWrapper= $('.header nav');
      const $menuToggle = $('.header .menu-toggle');
      const $menu = $menuWrapper.find('.nav.global');
      const $menuParent = $menu.find('.menu-item-has-children');
      $menuParent.append('<div class="menu-item-toggle"></div>');
      $menuToggle.on('click', function() {
        $menuWrapper.toggleClass('active').prev().toggleClass('open');
        $('body').toggleClass('no-scroll');
      });
      $menu.on('click', '.menu-item-toggle', function() {
        const $subMenu = $(this).siblings('.sub-menu');
        if($subMenu.is(':hidden')) {
          $(this).parent().removeClass('menu-closed').addClass('menu-open');
          $subMenu.slideDown();
        } else {
          $(this).parent().removeClass('menu-open').addClass('menu-closed');
          $subMenu.slideUp();
        }
      });
    },

    faqToggle: function() {
      const $accordion = $('.wp-block-yoast-faq-block');
      const $answer = $accordion.find('.schema-faq-answer');
      $answer.hide();
      $accordion.on('click', '.schema-faq-section', function(e) {
        $(this).toggleClass('active');
        $(this).find('.schema-faq-answer').slideToggle(300, 'swing');
      });
    },

    formOptIn: function() {
      $('input:radio[name="contact-opt-in"]').change(function(){
        if($(this).is(':checked') && $(this).val() == 'Yes'){
          $('input[name="contact-opt-out"]').val('0');
        } else {
          $('input[name="contact-opt-out"]').val('1');
        }
      });
    }

  };

  // Document ready actions
  $(function(){
    
    // Init
    sivomixx.init();
      
  });
    
})(jQuery);